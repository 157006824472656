@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap);
  .App {
    text-align: left;
  }
  
  .logo-animate {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
  
  .main-logo {
    height: 40px;
  }
  
  .App-nav {
    background-color: #fff;
    border-bottom-style: groove;
    height: 40px;
    padding: 20px;
    font-size: 18px;  
    color: white;
  }
  
  .App-nav-title {
    margin-left: 10px;
    float: left;
    font-size: 33px;
    font-family: Inter;
    font-weight: 600;
    color: #2a2a2a;
  }
  
  .App-nav-button {
    float: right;
    align-self: center;
    color: #2a2a2a;
    font-size: 30px;
  }
  
  .App-nav-img {
    cursor: pointer;
    height: 45px;
    border-radius: 50%;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
  
  .App-header {
    background-color: #ACABF4;
    border-bottom-style: groove;
    padding: 30px;
    font-size: 40px;
    text-align: left;
    color: #2a2a2a;
  }
  
  .Hero-text {
    max-width: 500px;
    letter-spacing: -1px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 300;
  }
  
  .Hero-paragraph {
    margin-top: -40px;
    font-size: 20px;
    max-width: 500px;
    font-family:'Inter', sans-serif;
  }
  
  .Hero-button {
    background-color: #fff;
    color: #2a2a2a;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border-color: #000;
    font-size: 20px;
    font-family:'Inter', sans-serif;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @-webkit-keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  .AppBody {
    width: 600px;
    margin: 0 auto;
  }
  
  .AppBody-fb-db {
    text-align: left;
    border-radius: 4px;
    padding: 15px;
    background: #f1f1f1
  }
  
  .AppBody .file {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
  }
  
  .App-form {
    text-align: left;
    border-radius: 4px;
  }
  
  .App-form .text {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    width: 490px;
  }
  
  .App-form .button {
      margin-left: 10px;
      color: #fff;
      background-color: #0275d8;
      border-color: #0275d8;
      white-space: nowrap;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: .5rem 1rem;
      font-size: 1rem;
      transition: all .2s ease-in-out;
  }
  
  .AppBody .file {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    margin-bottom: 20px;
  }
  
  .App-images {
    text-align: left;
    border: 4px solid #f1f1f1;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 50px;
  }
  
  .App-image {
    margin: 3px;
    width: 193px;
    vertical-align: top;
    border-radius: 4px;
  } 
.Form-label {
	font-size: 20px;
	font-family: 'Inter', sans-serif;
}

.skill-container {
	background-color: #00b48c !important;
}

.question-container {
	height: 70vh !important;
	overflow-x: hidden;
	overflow-y: scroll !important;
}

.btn-submit {
	background-color: #ffcb77 !important;
}

.strength-text-container {
	height: 80vh !important;
	overflow-x: hidden;
	overflow-y: scroll !important;
}

.value-list {
	-moz-column-count: 2;
	-moz-column-gap: 9px;
	-webkit-column-count: 2;
	-webkit-column-gap: 9px;
	column-count: 2;
	grid-column-gap: 9px;
	column-gap: 9px;
	line-height: 2.3 !important;
}

.list-item {
	font-size: 2.3vw !important;
	font-weight: bold !important;
}

.table-header {
	font-size: 1.9vw !important;
	color: #00b48c !important;
	font-weight: bold !important;
}

.table-title {
	font-size: 2.5vw !important;
	color: #00b48c !important;
	font-weight: bold !important;
}

.table-list-item {
	font-size: 1.6vw !important;
	font-weight: normal !important;
}

@media screen and (max-width: 768px) {
	.allSectOptionFromSkill {
		width: 75% !important;
		margin: auto;
	}

	.list-item {
		font-size: 5vw !important;
	}

	.table-title {
		font-size: 4vw !important;
	}

	.table-header {
		font-size: 3.7vw !important;
	}

	.table-list-item {
		font-size: 3.2vw !important;
		font-weight: normal !important;
	}
}

/* via https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 151, 0, 0.2);
  border-right: 1.1em solid rgba(255, 151, 0, 0.2);
  border-bottom: 1.1em solid rgba(255, 151, 0, 0.2);
  border-left: 1.1em solid #359474;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.drag-and-drop-wrapper {
	cursor: grab;
	height: 450px;
	border-radius: 13px;
	border: 2px solid #ffcb77;
	overflow-y: scroll;
	overflow-x: hidden;
}

.drag-enter {
	border: 2px dashed #ffcb77;
}

.drag-and-drop-wrapper:hover {
	background: #ffcb77;
	cursor: grab;
}

.row:active {
	cursor: grabbing;
}

.drag {
	color: #ffcb77;
	padding: 0px 3px;
	cursor: grab;
}

.drag:active {
	cursor: grabbing;
}

.dnd-item {
	background-color: #fff3cd !important;
	border-radius: 18px !important;
	font-size: 1vw !important;
	padding: 7px !important;
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.drag-box {
	background: #ffcb77 !important;
	padding: 0 !important;
	border-radius: 18px !important;
}

.drop-box {
	background: #fff3cd !important;
	border-radius: 18px !important;
}

.dnd-item {
	background: #ffffff !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.drag-and-drop-wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.drag-and-drop-wrapper {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.drag-box-heading,
.drop-box-heading {
	color: #ffcb77 !important;
}

@media screen and (max-width: 556px) {
	.dnd-item {
		font-size: 3vw !important;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}

@media screen and (min-width: 557px) and (max-width: 768px) {
	.dnd-item {
		font-size: 2.1vw !important;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}

.widget-container {
	display: flex;
}

.widget-container > * {
	height: 400px;
	width: 50%;
	padding: 10px;
}

.result-sub-btn {
	background-color: #ffcb77 !important;
	color: black !important;
	font-weight: bold !important;
}

:root {
  --primary: #FFCB77;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #FFCB77;
  background-color: var(--primary);
  color: #000;
  border: 1px solid #FFCB77;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #FFCB77;
  font-weight: 500;
  padding: 8px 20px;
  border: 1px solid #FFCB77;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #FFCB77;
  color: #000;
  transition: 250ms;
}
/* 
@media screen and (max-width: 960px) {
  .btn--primary {
    display: none;
  }
  .btn--outline {
    display: none;
  }
}
 */

/* .navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
} */

/* .navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
} */

/* .fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  padding-top: 1.5%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 40px;
} */

.nav-links {
	color: #fff;
	text-decoration: none;
	border-bottom: 4px solid transparent;
	font-size: large;
	font-weight: 400;
	transition: all 0.2s ease-out;
}

.nav-links:hover {
	text-decoration: none;
	color: #ffcb77;
	border-bottom: 4px solid #ffcb77;
}
/* .nav-links-2 {
  text-decoration: none;
  color: #FFCB77;
}

.nav-links-2:hover {
  text-decoration: none;
  color: #fff;
}

.fa-bars {
  color: #FFCB77;
  margin-right: 20px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
} */
/* 
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 90vh; */
/* position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    opacity: 1;
    transition: all 0.5s ease;
  } */

/* .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 87%;
    display: table;
  }

  .nav-links:hover {
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    margin-right: 20px;
    font-size: 2rem;
    opacity: 5 !important;
  }

  .nav-links-mobile {
    transform: translateY(100vh) !important;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #2EDEC9;
    color: #242424;
    transition: 250ms;
  }
}  */

.instaClass,
.linkedClass,
.twitClass {
  cursor: pointer;
}

.instaClass i,
.linkedClass i,
.twitClass i,
.footerLinks{
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}

.instaClass:hover i,
.linkedClass:hover i,
.twitClass:hover i {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.instaClass:hover i {
  color: red !important;
}

.linkedClass:hover i {
  color: #1D9BF0 !important;

}

.twitClass:hover i {
  color: #0A66C2 !important;

}

.footerLoginBtn{
  color : #198754;
  font-weight: 500;
  border: 2px solid #198754;
  background-color: transparent; 
  transition: all 0.2s ease-in-out;
}

.footerLoginBtn:hover{
  color : #fff;
  border: 2px solid #198754;
  background-color: #198754;
}

.footerLinks{
  font-size: 1.2rem;
}


.footerLinks:hover{
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  color: black !important;
}

@media screen and (max-width: 752px) {
  .footerMainContainer{
    width: 55% !important;
  }
  .footerDesCrip{
    width: 100% !important;
  }
  .emailFooterDes{
    font-size: 1.1rem !important;
  }
}






header {
  height: 60vh;
  /* position: relative; */
  background-image: url("https://media.istockphoto.com/photos/stairs-picture-id692318428?b=1&k=20&m=692318428&s=170667a&w=0&h=5LPgVwIHPDdV4kKmzlcM7K5XYkzxLSSS5pl2EhCg9ss=");
  background-size: cover;
  color: rgb(18, 18, 88);
}

.header-container {
  width: 80vw;
  /* position: absolute;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%); */
}

.header-container-inner {
  /* width: 50%; */
}

h1 {
  font-weight: bold;
}

.meaning-cover {
  height: 60vh;
  position: relative;
  background-image: url("https://media.istockphoto.com/photos/dark-blue-de-focused-blurred-motion-abstract-background-picture-id1216628493?b=1&k=20&m=1216628493&s=170667a&w=0&h=XgGPQTxjdXuWOVHtTPo-zZziQG-qffO5_K4dppHpccI=");
  background-size: cover;
  color: white;
}

.header-container {
  width: 80vw;
  position: absolute;
}

.header-container-inner {
  width: 50%;
}

h1 {
  font-weight: bold;
}

li {
  font-size: 30px;
  font-weight: bold;
}
li span {
  font-size: 14px;
  font-weight: normal;
}

.credentialsInputHeader .form-control::-webkit-input-placeholder {
  color: #fff;
}
.hyphenStyle{
  width: 20px;
  height: 20px;
  border-top: 2px solid #fff;
  margin-right: 0.5rem
}
.hero {
  padding: 25px;
  background: url("https://media.istockphoto.com/photos/stairs-picture-id692318428?b=1&k=20&m=692318428&s=170667a&w=0&h=5LPgVwIHPDdV4kKmzlcM7K5XYkzxLSSS5pl2EhCg9ss=");
  background-repeat: no-repeat;
  background-size: auto;
  /* width: 100vw; */
  height: 60vh;

}

i {
  font-size: 3rem;
}
.arrow {
  font-size: 5rem;
}




/* Contacts form */
.main_contact_form{
  background-image: url(/static/media/contactsBg.f53decdc.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and  (max-width: 1309px) {
  .contactForm{
    width: 60vw !important;
  }
}
@media screen and  (max-width: 702px) {
  .contactForm{
    width: 90vw !important;
  }
}
@media screen and  (max-width: 1050px) {
  .credentialsInput{
    width: 70% !important;
  }
}
@media screen and  (max-width: 768px) {
  .credentialsInput, .evalauationP{
    width: 100% !important;
  }
  .aboutMeRightSideClass{
    width: 80% !important;
  }
}

.mainSwiperCustomers .swiper-button-prev:after ,
.mainSwiperCustomers .swiper-button-next:after {
  color: #2377BF !important;
  content: '→';
  background-color: #fff;
  padding: 0.4rem;
  padding-top: 0.2rem;
  font-size : 2rem;
  font-weight: 700;
  border-radius: 50%;
  border: 1px solid #2377BF;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.mainSwiperCustomers .swiper-button-prev:after {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  content: '←';
}

.swiper-button-disabled {
  display: none !important
}
@media screen and (max-width: 768px) {
  .removingFixWidthOfP{
    width: 100% !important;
  }
}

.fancyWords p {
  font-family: 'Parisienne', cursive;
  font-size: 4rem;
  font-weight: 500;
  color: #00B48C;
}

@media screen and (max-width: 1403px) {
  .fancyWords p {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1055px) {
  .fancyWords p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .fancyParent{
    width: 90% !important;
  }
  .firstOfFancy{
    margin-right: 2.5rem;
    -webkit-transform: translateX(-0.5rem);
            transform: translateX(-0.5rem);
  }
   .fancyWords p {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .homePageFancy{
    font-size: 4.5rem !important
  }
}
/* body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.item1 {
  width: "20%";
}

.item1 {
  width: "80%";
} */

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: sans-serif;
}
