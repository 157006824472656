header {
  height: 60vh;
  /* position: relative; */
  background-image: url("https://media.istockphoto.com/photos/stairs-picture-id692318428?b=1&k=20&m=692318428&s=170667a&w=0&h=5LPgVwIHPDdV4kKmzlcM7K5XYkzxLSSS5pl2EhCg9ss=");
  background-size: cover;
  color: rgb(18, 18, 88);
}

.header-container {
  width: 80vw;
  /* position: absolute;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%); */
}

.header-container-inner {
  /* width: 50%; */
}

h1 {
  font-weight: bold;
}
