@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
.fancyWords p {
  font-family: 'Parisienne', cursive;
  font-size: 4rem;
  font-weight: 500;
  color: #00B48C;
}

@media screen and (max-width: 1403px) {
  .fancyWords p {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1055px) {
  .fancyWords p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .fancyParent{
    width: 90% !important;
  }
  .firstOfFancy{
    margin-right: 2.5rem;
    transform: translateX(-0.5rem);
  }
   .fancyWords p {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .homePageFancy{
    font-size: 4.5rem !important
  }
}