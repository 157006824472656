.widget-container {
	display: flex;
}

.widget-container > * {
	height: 400px;
	width: 50%;
	padding: 10px;
}

.result-sub-btn {
	background-color: #ffcb77 !important;
	color: black !important;
	font-weight: bold !important;
}
