  .App {
    text-align: left;
  }
  
  .logo-animate {
    animation: App-logo-spin infinite 20s linear;
  }
  
  .main-logo {
    height: 40px;
  }
  
  .App-nav {
    background-color: #fff;
    border-bottom-style: groove;
    height: 40px;
    padding: 20px;
    font-size: 18px;  
    color: white;
  }
  
  .App-nav-title {
    margin-left: 10px;
    float: left;
    font-size: 33px;
    font-family: Inter;
    font-weight: 600;
    color: #2a2a2a;
  }
  
  .App-nav-button {
    float: right;
    align-self: center;
    color: #2a2a2a;
    font-size: 30px;
  }
  
  .App-nav-img {
    cursor: pointer;
    height: 45px;
    border-radius: 50%;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
  
  .App-header {
    background-color: #ACABF4;
    border-bottom-style: groove;
    padding: 30px;
    font-size: 40px;
    text-align: left;
    color: #2a2a2a;
  }
  
  .Hero-text {
    max-width: 500px;
    letter-spacing: -1px;
    font-family: 'Libre Baskerville', serif;
    font-weight: 300;
  }
  
  .Hero-paragraph {
    margin-top: -40px;
    font-size: 20px;
    max-width: 500px;
    font-family:'Inter', sans-serif;
  }
  
  .Hero-button {
    background-color: #fff;
    color: #2a2a2a;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border-color: #000;
    font-size: 20px;
    font-family:'Inter', sans-serif;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .AppBody {
    width: 600px;
    margin: 0 auto;
  }
  
  .AppBody-fb-db {
    text-align: left;
    border-radius: 4px;
    padding: 15px;
    background: #f1f1f1
  }
  
  .AppBody .file {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
  }
  
  .App-form {
    text-align: left;
    border-radius: 4px;
  }
  
  .App-form .text {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    width: 490px;
  }
  
  .App-form .button {
      margin-left: 10px;
      color: #fff;
      background-color: #0275d8;
      border-color: #0275d8;
      white-space: nowrap;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: .5rem 1rem;
      font-size: 1rem;
      -webkit-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
  }
  
  .AppBody .file {
    padding: .5rem .75rem;
    font-size: 1rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    margin-bottom: 20px;
  }
  
  .App-images {
    text-align: left;
    border: 4px solid #f1f1f1;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 50px;
  }
  
  .App-image {
    margin: 3px;
    width: 193px;
    vertical-align: top;
    border-radius: 4px;
  } 