.Form-label {
	font-size: 20px;
	font-family: 'Inter', sans-serif;
}

.skill-container {
	background-color: #00b48c !important;
}

.question-container {
	height: 70vh !important;
	overflow-x: hidden;
	overflow-y: scroll !important;
}

.btn-submit {
	background-color: #ffcb77 !important;
}

.strength-text-container {
	height: 80vh !important;
	overflow-x: hidden;
	overflow-y: scroll !important;
}

.value-list {
	-moz-column-count: 2;
	-moz-column-gap: 9px;
	-webkit-column-count: 2;
	-webkit-column-gap: 9px;
	column-count: 2;
	column-gap: 9px;
	line-height: 2.3 !important;
}

.list-item {
	font-size: 2.3vw !important;
	font-weight: bold !important;
}

.table-header {
	font-size: 1.9vw !important;
	color: #00b48c !important;
	font-weight: bold !important;
}

.table-title {
	font-size: 2.5vw !important;
	color: #00b48c !important;
	font-weight: bold !important;
}

.table-list-item {
	font-size: 1.6vw !important;
	font-weight: normal !important;
}

@media screen and (max-width: 768px) {
	.allSectOptionFromSkill {
		width: 75% !important;
		margin: auto;
	}

	.list-item {
		font-size: 5vw !important;
	}

	.table-title {
		font-size: 4vw !important;
	}

	.table-header {
		font-size: 3.7vw !important;
	}

	.table-list-item {
		font-size: 3.2vw !important;
		font-weight: normal !important;
	}
}
