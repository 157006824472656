.drag-and-drop-wrapper {
	cursor: grab;
	height: 450px;
	border-radius: 13px;
	border: 2px solid #ffcb77;
	overflow-y: scroll;
	overflow-x: hidden;
}

.drag-enter {
	border: 2px dashed #ffcb77;
}

.drag-and-drop-wrapper:hover {
	background: #ffcb77;
	cursor: grab;
}

.row:active {
	cursor: grabbing;
}

.drag {
	color: #ffcb77;
	padding: 0px 3px;
	cursor: grab;
}

.drag:active {
	cursor: grabbing;
}

.dnd-item {
	background-color: #fff3cd !important;
	border-radius: 18px !important;
	font-size: 1vw !important;
	padding: 7px !important;
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.drag-box {
	background: #ffcb77 !important;
	padding: 0 !important;
	border-radius: 18px !important;
}

.drop-box {
	background: #fff3cd !important;
	border-radius: 18px !important;
}

.dnd-item {
	background: #ffffff !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.drag-and-drop-wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.drag-and-drop-wrapper {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.drag-box-heading,
.drop-box-heading {
	color: #ffcb77 !important;
}

@media screen and (max-width: 556px) {
	.dnd-item {
		font-size: 3vw !important;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}

@media screen and (min-width: 557px) and (max-width: 768px) {
	.dnd-item {
		font-size: 2.1vw !important;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}
