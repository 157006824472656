.mainSwiperCustomers .swiper-button-prev:after ,
.mainSwiperCustomers .swiper-button-next:after {
  color: #2377BF !important;
  content: '→';
  background-color: #fff;
  padding: 0.4rem;
  padding-top: 0.2rem;
  font-size : 2rem;
  font-weight: 700;
  border-radius: 50%;
  border: 1px solid #2377BF;
  transform: translateX(-10px);
}

.mainSwiperCustomers .swiper-button-prev:after {
  transform: translateX(0px);
  content: '←';
}

.swiper-button-disabled {
  display: none !important
}