.instaClass,
.linkedClass,
.twitClass {
  cursor: pointer;
}

.instaClass i,
.linkedClass i,
.twitClass i,
.footerLinks{
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}

.instaClass:hover i,
.linkedClass:hover i,
.twitClass:hover i {
  transform: translateY(-10px);
}

.instaClass:hover i {
  color: red !important;
}

.linkedClass:hover i {
  color: #1D9BF0 !important;

}

.twitClass:hover i {
  color: #0A66C2 !important;

}

.footerLoginBtn{
  color : #198754;
  font-weight: 500;
  border: 2px solid #198754;
  background-color: transparent; 
  transition: all 0.2s ease-in-out;
}

.footerLoginBtn:hover{
  color : #fff;
  border: 2px solid #198754;
  background-color: #198754;
}

.footerLinks{
  font-size: 1.2rem;
}


.footerLinks:hover{
  transform: translateY(-6px);
  color: black !important;
}

@media screen and (max-width: 752px) {
  .footerMainContainer{
    width: 55% !important;
  }
  .footerDesCrip{
    width: 100% !important;
  }
  .emailFooterDes{
    font-size: 1.1rem !important;
  }
}



