.hero {
  padding: 25px;
  background: url("https://media.istockphoto.com/photos/stairs-picture-id692318428?b=1&k=20&m=692318428&s=170667a&w=0&h=5LPgVwIHPDdV4kKmzlcM7K5XYkzxLSSS5pl2EhCg9ss=");
  background-repeat: no-repeat;
  background-size: auto;
  /* width: 100vw; */
  height: 60vh;

}

i {
  font-size: 3rem;
}
.arrow {
  font-size: 5rem;
}




/* Contacts form */
.main_contact_form{
  background-image: url('../assets/images/contactsBg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and  (max-width: 1309px) {
  .contactForm{
    width: 60vw !important;
  }
}
@media screen and  (max-width: 702px) {
  .contactForm{
    width: 90vw !important;
  }
}
@media screen and  (max-width: 1050px) {
  .credentialsInput{
    width: 70% !important;
  }
}
@media screen and  (max-width: 768px) {
  .credentialsInput, .evalauationP{
    width: 100% !important;
  }
  .aboutMeRightSideClass{
    width: 80% !important;
  }
}
