.meaning-cover {
  height: 60vh;
  position: relative;
  background-image: url("https://media.istockphoto.com/photos/dark-blue-de-focused-blurred-motion-abstract-background-picture-id1216628493?b=1&k=20&m=1216628493&s=170667a&w=0&h=XgGPQTxjdXuWOVHtTPo-zZziQG-qffO5_K4dppHpccI=");
  background-size: cover;
  color: white;
}

.header-container {
  width: 80vw;
  position: absolute;
}

.header-container-inner {
  width: 50%;
}

h1 {
  font-weight: bold;
}

li {
  font-size: 30px;
  font-weight: bold;
}
li span {
  font-size: 14px;
  font-weight: normal;
}
